import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Ana from './pages/Ana/Ana';
import Kurumsal from './pages/Kurumsal/Kurumsal';
import Katalog from './pages/Katalog/Katalog';
import Iletisim from './pages/Iletisim/Iletisim';

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Ana />} />
      <Route path="/kurumsal" element={<Kurumsal />} />
      <Route path="/katalog" element={<Katalog />} />
      <Route path="/iletisim" element={<Iletisim />} />
    </Routes>
  </Router>
);

export default App;