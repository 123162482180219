import React, { useEffect } from 'react';

const Katalog = () => {
    useEffect(() => {
        window.open('/katalog.pdf', '_blank');
    }, []);

    return (
        <div>
            <p>Dosya açılmadıysa, <a href="/katalog.pdf" target="_blank" rel="noopener noreferrer">buraya tıklayın.</a>.</p>
        </div>
    );
};

export default Katalog;