import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!isMenuOpen);

  return (
    <header className="header">
      <img src="/logo.png" alt="Company Logo" className="logo" />
      <nav className={`navbar ${isMenuOpen ? 'open' : ''}`}>
        <NavLink exact to="/" activeClassName="active">Anasayfa</NavLink>
        <NavLink to="/kurumsal" activeClassName="active">Kurumsal</NavLink>
        <NavLink to="/katalog" activeClassName="active">Katalog</NavLink>
        <NavLink to="/iletisim" activeClassName="active">İletişim</NavLink>
        <a href="https://b2b.plsfiltre.com/giris-yap" target="_blank" rel="noopener noreferrer" className="sign-in-button">B2B Giriş</a>
      </nav>
      <button className="hamburger" onClick={toggleMenu}>
        ☰
      </button>
    </header>
  );
};

export default Header;