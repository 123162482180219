import React from 'react';
import Layout from '../../components/Layout/Layout';
import Section from '../../components/Section/Section';
import './Ana.css';

const Ana = () => {
    const backgroundImageUrl = '/bina.png';

    return (
        <Layout>
            <Section className="section">
                <img src="/bina.png" alt="Background" />
                <h2 className="section-title">PLS Filtre'yi Keşfedin</h2>
                <p>
                Palas Otomotiv’in gurur kaynağı olan PLS Filtre, otomotiv sektöründe uzun yıllara dayanan deneyimiyle ön plana çıkıyor. 1991 yılından bu yana filtre üretiminde uzmanlaşmış olan PLS Filtre, sektördeki engin bilgi birikimi ve yenilikçi yaklaşımıyla adından söz ettiriyor.
PLS Filtre, 1300'ün üzerinde ürün çeşidiyle geniş bir filtre portföyüne sahip. Bu zengin ürün yelpazesi, hem bireysel kullanıcıların hem de kurumsal müşterilerin ihtiyaçlarına hitap edecek şekilde tasarlandı. Şirketimiz, her bir ürününde kaliteyi ön planda tutarak, müşterilerine güvenilir ve etkili çözümler sunmayı hedefliyor. 
Küresel bir vizyona sahip olan PLS Filtre, yalnızca Türkiye’de değil, aynı zamanda Almanya, Fransa, İtalya, İspanya, Birleşik Krallık, Hollanda, Polonya, Çek Cumhuriyeti, Yunanistan ve Meksika gibi 10 farklı ülkede de şubeleriyle hizmet veriyor. Bu geniş uluslararası ağ, markanın global pazarda ne kadar güçlü olduğunu ve müşteri memnuniyetine verdiği önemi gözler önüne seriyor.
PLS Filtre'nin tescilli markası, sektördeki en yüksek standartları temsil ederken, yenilikçi teknolojiler ve sürekli Ar-Ge çalışmaları sayesinde müşteri ihtiyaçlarını en iyi şekilde karşılamayı sürdürüyor. Şirket, filtre üretimindeki kalitesini ve çeşitliliğini artırarak, otomotiv sektöründe daha da büyük başarılara imza atmayı hedefliyor.
PLS Filtre, güvenilirliğinden ve kalite anlayışından ödün vermeden, sektörün lider markalarından biri olma yolunda emin adımlarla ilerliyor. Bu güvenilir markanın sunduğu çözümler, araç sahiplerinin ve işletmelerin filtreleme ihtiyaçlarını en iyi şekilde karşılamaya devam edecek. </p>
            </Section>

            {/* Contact Section */}
            <Section
                title="İletişime Geçin"
                className="section"
                style={{
                    padding: '50px',
                    backgroundColor: '#f8f9fa'
                }}
            >
                <form className="contact-form">
                    <label htmlFor="name">İsim:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="İsminiz"
                    />

                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email Adresiniz"
                    />

                    <label htmlFor="phone">Tel No:</label>
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        placeholder="Telefon Numaranız"
                    />

                    <label htmlFor="notes">Not:</label>
                    <textarea
                        id="notes"
                        name="notes"
                        rows="4"
                        placeholder="Eklemek İstedikleriniz"
                    ></textarea>

                    <button
                        type="send"
                    >
                        Gönder
                    </button>
                </form>
            </Section>
        </Layout>
    );
};

export default Ana;