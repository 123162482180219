import React from 'react';
import Layout from '../../components/Layout/Layout';
import Section from '../../components/Section/Section';
import './Iletisim.css';

const Iletisim = () => {
    return (
        <Layout>
            <Section className="contact-section">
                <h2>İletişime Geçin</h2>
                <form className="contact-form">
                    <label htmlFor="name">İsim:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="İsminiz"
                    />

                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email Adresiniz"
                    />

                    <label htmlFor="phone">Tel No:</label>
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        placeholder="Telefon Numaranız"
                    />

                    <label htmlFor="notes">Not:</label>
                    <textarea
                        id="notes"
                        name="notes"
                        rows="6"
                        placeholder="Eklemek İstedikleriniz"
                    ></textarea>

                    <button
                        type="submit"
                    >
                        Gönder
                    </button>
                </form>
            </Section>
        </Layout>
    );
};

export default Iletisim;