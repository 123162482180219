import React from 'react';
import Layout from '../../components/Layout/Layout';
import Section from '../../components/Section/Section';
import './Kurumsal.css';

const Kurumsal = () => {
    return (
        <Layout>
            <Section className="corporate-section">
                <div className="corporate-content">
                    <img src="/bina2.jpg" alt="Company" className="corporate-image" />
                    <div className="corporate-text">
                        <h2>Firmamız Hakkında</h2>
                        <p>
                            Firmamız, sektördeki uzun yıllara dayanan deneyimi ve sürekli yenilikçi yaklaşımı ile ön plana çıkmaktadır. 1985 yılında kurulan firmamız, kaliteli ürünleri ve müşteri memnuniyetine verdiği önem ile sektörde önemli bir yere sahiptir. 
                            Ürün portföyümüz, geniş bir yelpazede çeşitli ihtiyaçlara hitap eden çözümler sunmaktadır. Her geçen gün ürün çeşitlerimizi artırarak, müşterilerimize en iyi hizmeti sunmayı amaçlıyoruz. 
                            Ulusal ve uluslararası pazarda etkin bir şekilde faaliyet gösteren firmamız, global düzeyde de rekabetçi ve yenilikçi bir yaklaşımla çalışmaktadır. 
                            Firmamızın sunduğu ürünler, hem bireysel hem de kurumsal müşterilerimizin ihtiyaçlarını karşılamakta ve sektörün gereksinimlerine uygun olarak geliştirilmiştir.
                        </p>
                    </div>
                </div>
            </Section>

            {/* Contact Section */}
            <Section
                title="İletişime Geçin"
                className="section"
                style={{
                    padding: '50px',
                    backgroundColor: '#f8f9fa'
                }}
            >
                <form className="contact-form">
                    <label htmlFor="name">İsim:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="İsminiz"
                    />

                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email Adresiniz"
                    />

                    <label htmlFor="phone">Tel No:</label>
                    <input
                        type="tel"
                        id="phone"
                        name="phone"
                        placeholder="Telefon Numaranız"
                    />

                    <label htmlFor="notes">Not:</label>
                    <textarea
                        id="notes"
                        name="notes"
                        rows="4"
                        placeholder="Eklemek İstedikleriniz"
                    ></textarea>

                    <button
                        type="send"
                    >
                        Gönder
                    </button>
                </form>
            </Section>
        </Layout>
    );
};

export default Kurumsal;